import { ApolloClient, DocumentNode, useApolloClient } from "@apollo/client";
import GraphQLSimplifier from "../GraphQL/GraphQLSimplifier";
import InputWrapper from "../GraphQL/InputWrapper";

export default abstract class PromiseQuery<TData, TInput> {
    private readonly _query: DocumentNode;
    private readonly _apolloClient: ApolloClient<object>;

    public constructor(query: DocumentNode) {
        this._apolloClient = useApolloClient();
        this._query = query;
        this.execute = this.execute.bind(this);
    }

    public abstract get name(): string;

    public get query(): DocumentNode {
        return this._query;
    }

    public execute(input: TInput): Promise<TData> {
        const inputWrapper: InputWrapper<TInput> = { input };
        return this._apolloClient
            .query<TData>({
                query: this._query,
                fetchPolicy: "no-cache",
                variables: inputWrapper,
            })
            .then((result) => {
                if (!result) {
                    return null;
                }

                return GraphQLSimplifier.simplify(result.data);
            });
    }
}
