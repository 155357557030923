/*
    copyright (c) computer-L.a.N. GmbH (http://www.computer-lan.de).
    alle Rechte vorbehalten.
*/

export const ZahlungsmethodeTypenSammlung = {
    Karte: "bf29a453-a40e-4fed-b7d8-7bdecacd93a4",
    GiroPay: "014ebf6a-65df-40f6-bd02-8c3fbaa63991",
    Sepa: "b96e68fc-0c47-4a66-b7b6-d046c67065d9",
    AufRechnung: "061aa378-a651-4bec-a47c-2ff40e0fac98",
    PayPal: "f9b61537-064a-44ec-8f46-a23f3a9933bd",
} as const

type ZahlungsmethodeTyp = typeof ZahlungsmethodeTypenSammlung.Karte
    | typeof ZahlungsmethodeTypenSammlung.GiroPay
    | typeof ZahlungsmethodeTypenSammlung.Sepa
    | typeof ZahlungsmethodeTypenSammlung.AufRechnung
    | typeof ZahlungsmethodeTypenSammlung.PayPal;
export default ZahlungsmethodeTyp;