import { gql } from "@apollo/client";
import Command from "../../../Basis/Commands/Command";
import Feld from "../../DomainDrivenDesign/Commands/Feld";
import Filter from "../../DomainDrivenDesign/Commands/Filter";

export type ErstelleProvisionenCsvExportInput = {
    rechnungId?: string;
    gutschriftId?: string;
    felder: Feld[];
    filter: Filter[];
}

export default class ErstelleProvisionenCsvExport extends Command<void, ErstelleProvisionenCsvExportInput> {
    public static readonly commandName = "Exporte_ErstelleProvisionenCsvExport";

    private static readonly mutation = gql`
        mutation ${ErstelleProvisionenCsvExport.commandName}($input: ${ErstelleProvisionenCsvExport.commandName}) {
            ${ErstelleProvisionenCsvExport.commandName}(input: $input)
        }
    `;

    public constructor(refetchQueries: string[] = []) {
        super(ErstelleProvisionenCsvExport.mutation, refetchQueries);
    }

    public get name(): string {
        return ErstelleProvisionenCsvExport.commandName;
    }
}